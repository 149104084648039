import React from "react";

const Statement = () => {
  return (
    <div className="container">
        <h2 style={{"margin": "10% 0%", 'text-transform': 'none', "text-align": "center", "line-height": "55px"}}>Say goodbye to traditional head hunting and embrace a smarter, more efficient way to find your perfect tech hires</h2>
    </div>
  )
}

export default Statement;